// Variables

$primary-yellow: #e3a300;

// Reference: ResponsiveService

// Extra small: xs – 480px
// Small: sm – 768px
// Medium: md – 992px
// Large: lg – 1200px

// Mixins
@mixin xsUp() {
    @media screen and (min-width: 480px) {
        @content;
    }
}
@mixin smUp {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin mdUp() {
    @media screen and (min-width: 992px) {
        @content;
    }
}
@mixin lgUp() {
    @media screen and (min-width: 1200px) {
        @content;
    }
}

@mixin xsDown() {
    @media screen and (max-width: 480px) {
        @content;
    }
}
@mixin smDown {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin mdDown() {
    @media screen and (max-width: 992px) {
        @content;
    }
}
@mixin lgDown() {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
