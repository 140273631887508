// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-typography-config(
    $font-family: 'Assistant, sans-serif',
);
@include mat.core($custom-typography);
@import './variables.scss';

a.mat-tab-link,
a.mat-tab-link:hover {
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    opacity: 1;
    width: min-content;
    min-width: min-content;
    padding: 0 0.25vw;
    margin: auto 1.5vw auto 1.5vw;
    text-decoration: none;
    color: black;
}

/* Style loading spinner */
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $primary-yellow;
}

.fab-icon-button > span.mat-button-wrapper {
    padding: 0;
}

.mat-menu-panel {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 20px 0px;
    border-radius: 10px;
}

app-events-sort .mat-button-wrapper {
    border-bottom: 2px solid $primary-yellow;
    padding-bottom: 6px;
}

// App loading spinner styles
mat-progress-spinner.transitioning-spinner {
    position: fixed;
    top: calc(40vh - 50px);
    left: calc(50vw - 50px);
    z-index: 10001;
}

$md-symposium: (
    50: #030303,
    100: #f7e3b3,
    200: #f1d180,
    300: #ebbf4d,
    400: #e7b126,
    500: $primary-yellow,
    600: #e09b00,
    700: #dc9100,
    800: #d88800,
    900: #d07700,
    A100: #fffcf8,
    A200: #ffe3c5,
    A400: #ffca92,
    A700: #ffbe79,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$md-symposium-accent: (
    50: #f6f0e0,
    100: #e9dab3,
    200: #dbc180,
    300: #cca84d,
    400: #c19526,
    500: $primary-yellow,
    600: #af7a00,
    700: #a66f00,
    800: #9e6500,
    900: #8e5200,
    A100: #ffddba,
    A200: #ffc487,
    A400: #ffac54,
    A700: #ff9f3b,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette($md-symposium);
$frontend-accent: mat.define-palette($md-symposium-accent);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$frontend-theme: mat.define-light-theme($frontend-primary, $frontend-accent, $frontend-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);
