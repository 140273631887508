app-table,
app-session {
    mat-form-field.mat-form-field {
        min-width: 0;
        align-self: center;
    }
    mat-form-field.mat-form-field-appearance-outline {
        font-size: 16px;
        min-width: 50px;

        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
            transform: translateY(-12px) scale(0.75);
        }
        .mat-form-field-label {
            transform: translateY(1px);
            margin-top: -8px;
        }

        div.mat-form-field-outline {
            color: #e3a300;
            top: 0;
        }

        div.mat-form-field-infix {
            border-top: 0;
            padding: 0.5rem 0;
        }

        div.mat-form-field-wrapper {
            padding: 0;
        }

        div.mat-form-field-subscript-wrapper {
            top: 27px;
        }
    }
    mat-select {
        div.mat-select-trigger > div.mat-select-arrow-wrapper {
            transform: unset;

            & > div.mat-select-arrow {
                width: 0;
                height: 0;
                border: solid #e3a300;
                transform: rotate(45deg);
                border-width: 0 2px 2px 0;
                margin: 0 4px;
                padding: 4px;
            }
        }
    }
}

app-live-sessions app-table,
app-user-preview-dialog {
    button.mat-flat-button.mat-primary {
        background: #fff;
        font-weight: bold;
        color: #e3a300;
        border-radius: 24.5px;
        margin-top: 1rem;
    }
}

.cdk-overlay-container {
    z-index: 10000;
}

.opentok-dialog-container {
    height: 100vh;
    width: 100vw;

    mat-dialog-container {
        background: #15171d;
        border-radius: 0;
    }
}

.videosdk-dialog-container {
    height: 100vh;
    width: 100vw;

    mat-dialog-container {
        background: #212032;
        border-radius: 0;
        padding: 0;
    }
}

.user-preview-dialog-container {
    mat-dialog-container {
        padding: 1rem 2rem;
        overflow: unset;
    }
}

app-opentok-dialog {
    [mat-dialog-actions] > button {
        background-color: #20232c;
        font-size: 1rem;
    }

    .highlight-border {
        border: solid 2px #e3a300;
    }

    .cover {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}

app-opentok-dialog,
app-user-preview-dialog {
    button.mat-fab.mat-accent,
    button.mat-mini-fab.mat-accent {
        color: #fff;

        &.gray-background {
            background-color: #888888;
        }
    }
}

[mat-fab].big-icon {
    .mat-button-wrapper {
        padding: 0;
        line-height: 40px;
    }
    mat-icon {
        width: 40px;
        height: 40px;
        font-size: 40px;
    }
}
app-event-admin-dash-live-sessions {
    div.mat-tab-label {
        opacity: 1;
        font-weight: bold;
    }
}
