/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import './variables.scss';
@import './material-overrides.scss';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: 'Assistant', sans-serif !important;
    letter-spacing: 0.1px;
}

a {
    text-decoration: none;

    &.btn-primary:hover {
        background-color: #b68200 !important;
        border-color: transparent !important;
    }
}

.fg1-yellow-link {
    color: #e3a300 !important;
    cursor: pointer !important;
    
    &:hover {
        text-decoration: underline !important;
    }
}

.fg1-blue-link {
    color: #007bff !important;
    cursor: pointer;

    &:hover {
        text-decoration: underline !important;
    }
}

.text-error {
    color: #dc3545;
}

.grey-color {
    color: #afafaf;
}

.white-color-bkg {
    background: white;
}

.btn-primary {
    color: #ffffff;
    font-weight: 600;
    background-color: $primary-yellow;
    border: 3px solid $primary-yellow;
    border-color: $primary-yellow;
}

.btn-primary:disabled {
    background-color: #989898;
    border-color: #989898;
}

.btn-primary:hover:enabled {
    background-color: rgb(182, 130, 0) !important;
    border-color: transparent !important;
    box-shadow: transparent !important;
}

.btn-primary:focus {
    background-color: rgb(182, 130, 0) !important;
    box-shadow: 0 0 0.2rem $primary-yellow !important;
    border-color: $primary-yellow;
}

.btn-primary:active {
    background-color: rgb(182, 130, 0) !important;
    border-color: rgb(182, 130, 0) !important;
    box-shadow: 0 0 0.2rem $primary-yellow !important;
}

button.btn-outline-primary {
    color: $primary-yellow;
    font-weight: 600;
    border: 2px solid $primary-yellow;
    &:disabled {
        background-color: #989898;
        border-color: #989898;
    }
    &:hover:enabled,
    &:focus,
    &:active {
        color: #fff;
        background-color: $primary-yellow !important;
        border-color: transparent !important;
        box-shadow: 0 0 0 0.2rem rgb(227 163 0 / 50%);
    }
}

button.ghost {
    display: inline-block;
    color: inherit;
    background: transparent;
    box-shadow: none;
    border-style: none;
    cursor: pointer;
}

.btn-secondary {
    color: $primary-yellow;
    font-weight: 600;
    background-color: #ffffff;
    border: 3px solid $primary-yellow;
    border-radius: 5px;
}

.btn-secondary:focus {
    box-shadow: 0 0 0.2rem $primary-yellow !important;
    border-color: $primary-yellow;
}

.btn-secondary-revert-outline:focus {
    outline: revert;
}

.btn-secondary:active {
    background-color: rgb(182, 130, 0) !important;
    border-color: rgb(182, 130, 0) !important;
    box-shadow: 0 0 0.2rem $primary-yellow !important;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #e3a000;
    border-color: #e3a000;
}

.btn-secondary:disabled {
    background-color: #989898;
    border-color: #989898;
}

.btn-secondary:hover:enabled {
    color: #ffffff;
    background-color: $primary-yellow;
    border-color: $primary-yellow;
}

.btn-tertiary {
    color: $primary-yellow;
    font-weight: 600;
    background-color: #ffffff;
    border: 3px solid transparent;
    border-radius: 5px;
}

.btn-tertiary:disabled {
    background-color: #989898;
}

.btn-tertiary:hover:enabled {
    border-color: rgb(182, 130, 0);
    box-shadow: transparent !important;
}

.btn-tertiary:focus {
    border-color: rgb(182, 130, 0);
    box-shadow: 0 0 0.2rem $primary-yellow !important;
}

.btn-tertiary:active {
    background-color: rgb(182, 130, 0) !important;
    box-shadow: 0 0 0.2rem $primary-yellow !important;
}

.ngx-pagination {
    padding-left: 0px;
}

.ngx-pagination .current {
    color: black !important;
    background: none !important;
    border-bottom: 2px solid $primary-yellow;
}

.ngx-pagination li {
    font-weight: 600;
}

/*From Bootstrap 3.3.7 - for pagination*/
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 3px;
}
.pagination > li {
    display: inline;
}
.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #112842;
    text-decoration: none;
    background-color: #fff;
    border-right: 0.5px solid #ddd;
    border-left: 0.5px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-left: 0.5px solid #ddd;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #1c5d99;
    border-color: #1c5d99;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

fieldset,
label {
    margin: 0;
    padding: 0;
}

.m20 {
    margin: 20px;
}

.mb0 {
    margin-bottom: 0;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb80 {
    margin-bottom: 80px;
}

.ml22 {
    margin-left: 22px;
}

.mr22 {
    margin-right: 22px;
}

.w100p {
    width: 100%;
}

.w300 {
    width: 100%;
    max-width: 300px;
}

.p10 {
    padding: 10px;
}

.pt20 {
    padding-top: 20px;
}

.pt10 {
    padding-top: 10px;
}

.no-border {
    border: 0;
}

.no-outline {
    outline: none !important;
}

.height-90 {
    height: 90px;
}

.pointer {
    cursor: pointer;
}

.search-pag {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}

/* General text */

.header-text {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

.light-font-color {
    color: #888;
}

.primary-yellow {
    color: $primary-yellow;
}

.f {
    font-size: 12px;
}

.f18 {
    font-size: 18px;
}

.bold {
    font-weight: bold;
}

.body-text {
    margin-bottom: 15px;
}

.container {
    max-width: 1240px !important;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.relative {
    position: relative;
}

.fg-spinner {
    width: 35px;
    display: block;
    margin: auto;
}

.hover-black:hover {
    color: #000;
}

/* style the warning icon/svg to black */
.simple-notification-selector {
    position: absolute;
    right: 5vw;
    bottom: -2vh;
}

.warn svg.simple-notification-svg {
    filter: invert(0%) sepia(83%) saturate(7500%) hue-rotate(180deg) brightness(45%) contrast(115%);
}

.title {
    font-family: 'Playfair Display';
    font-weight: bold;
}

.fs1_9 {
    font-size: 1.9rem;
}

.fs12 {
    font-size: 12px;
}

.center {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear {
    clear: both;
}

.background-white {
    background: #fff;
}

.width-33-pc {
    width: 33%;
}

.width-50-pc {
    width: 50%;
}

.no-list {
    list-style-type: none;
}

.white-space-nowrap {
    white-space: nowrap;
}

// App loading overlay
.transitioning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    opacity: 0.55;
    background: black;
}

// App loading overlay
.transitioning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    opacity: 0.55;
    background: black;
}

.admin-main-container {
    padding-top: 0.25em;
    padding-bottom: 5vh;
    background: white;
    width: 100%;
    min-height: calc(100vh - 170px);
}

.admin-title {
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 1.9rem;
    margin-left: 24px;
}

.admin-tab-links-container {
    position: relative;
    z-index: 100;
    width: 100%;
}

.admin-router-container {
    position: relative;
    height: max-content;
    width: 100%;
    margin: 1em auto 0 auto;
    overflow: auto;
    box-shadow: 0 0 5px 0 #e0e0e0;
    border-radius: 8px;
    padding: 50px;
}

.admin-actions {
    margin-bottom: -15px;
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.plan-types {
    mat-tab-group {
        .mat-tab-label {
            height: 96px;
        }
    }
}

.private-event-tooltip,
.feature-tooltip {
    &.show {
        opacity: 1 !important;
        z-index: 1001 !important;
    }

    .arrow::before {
        // border-top-color: #414141;
        // border-bottom-color: #414141;
    }

    .tooltip-inner {
        max-width: 300px !important;
        text-align: left;
        background-color: #414141;
        padding: 14px;
        color: white;

        hr {
            border-color: white;
            margin: 0.5rem 0;
        }

        a.link {
            cursor: pointer;
            font-weight: 700;
        }
    }
}

.rollout.alert-success {
    color: #4e4e4e;
    margin: 20px 0 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f0ede5;
    border-color: #f0d489;
}

ngb-tooltip-window.reactivate-tip.show {
    opacity: 1 !important;

    &.is-mobile {
        left: -53px !important;
    }
}

ngb-tooltip-window.reactivate-tip .tooltip-inner {
    width: 250px !important;
    max-width: 250px !important;
    max-width: 300px !important;
    text-align: left;
    background-color: #414141;
    padding: 14px;
    color: white;

    hr {
        border-color: white;
        margin: 0.5rem 0;
    }

    a.link {
        cursor: pointer;
        font-weight: 700;
    }
}

app-feedback-form {
    .ql-editor {
        height: 200px;
    }
}

#fc_frame {
    z-index: 9999 !important;

    @include xsDown {
        display: none !important;
    }
}

// Pencil Icon for Editing Styles
.pres-ctrl-icon {
    font-size: 1.5em;
    cursor: pointer;
}

.pres-edit-icon {
    color: #e3a300;
}

.pres-del-icon {
    color: silver;
}

.white {
    color: white;
}

// Admin portal fonts

.subheader {
    color: #808080;
    font-size: 0.9em;
    opacity: 0.6;
    margin: 0;
}

.judging-section-title {
    font-weight: 600;
}

.download-button {
    color: $primary-yellow;
}

.preview-dummy-text {
    color: #808080;
    opacity: 0.6;
}

app-rollout-feature-alert {
    a {
        color: $primary-yellow;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            color: $primary-yellow;
            text-decoration: underline;
        }
    }
}

.loading-indicator {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.donation-popup-wrapper {
    position: fixed;
    right: 1rem;
    bottom: 6.25rem;
    z-index: 999;

    @media (max-width: 993px) {
        right: 1.5rem;
        bottom: 4.3rem;
    }
}

.fg1-input-container {
    border: 2px solid #e3a300;
    border-radius: 0.5em;
    padding: 0.25em 0.5em;
    margin: 0.75em 0 0.35em 1em;
}

.fg1-input {
    border: none;
    width: 100%;
    height: 100%;
}

.fg1-input:focus {
    outline: none;
}

.fg1-input::placeholder {
    opacity: 0.5;
}

.fg1-input-add-btn-container {
    margin: 0.9em 1em 0.5em 0;
}

.fg1-btn-no-height {
    line-height: 1;
}

.fg1-btn-25p-padding {
    padding: 0.25px;
}

.fg1-success-color {
    color: #00b51e;
}

.fg1-disabled-color {
    color: #989898;
}

.responsive-youtube-player youtube-player {
    overflow: hidden;
    padding-bottom: 70%;
    position: relative;
    height: 0;
    display: block;

    > iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.mobile-container {
    padding-bottom: 100px;
}

/*
    Push the top of the menu below the safe area
    so it doesn't overlay the status bar
*/
ion-menu,
ion-toolbar {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
}

/*
    Pull the menu header up to account for the menu top margin
*/
ion-menu ion-header:first-child,
ion-menu ion-content:first-child {
    margin-top: calc(constant(safe-area-inset-top) * -1);
    margin-top: calc(env(safe-area-inset-top) * -1);
}

.fg1-notification {
    &.fg1-notification--mobile {
        margin-bottom: 80px;
    }
}

.ion-page {
    &.stay-top {
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100%;
    }
}

.toggle-section {
    display: flex;
    border-radius: 0.5em;
    padding: 0.7em;
    align-items: center;
    background-color: rgb(128, 128, 128, 0.2);
    border: 1em black;
    justify-content: space-between;
    p {
        padding: 0 !important;
        margin: 0 !important;
    }
}

mat-label.stage {
    display: inline-block;
    font-size: 1.25em;
    height: 100%;
}

.stage-select-form-field {
    display: inline-block;
    margin: 0 2em;
    max-width: 100%;
    text-align: center;
}

.stage-select {
    text-align-last: center;
    background-color: white;
    font-size: 1.25em;
    color: #e3a300;
}

/** Empty Presentations Table **/

.pres-table-empty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    z-index: 101;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    > div, p {
        font-weight: bold;
        font-size: 2em;
        color: #e3a300;
        z-index: 102;
        overflow: hidden;
        margin: 0;
    }
}

.presentations-table-container {
    position: sticky;
    overflow-y: hidden;
    border-radius: 8px;
    padding: 1.5em 0;
    min-width: 450px;
    box-shadow: 0 0 5px 0 #e0e0e0;
    background: white;

    .presentations-table-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .export-button {
            margin-left: 16px;
            color: white;
        }
    }
}
